@import '../../../../styles/mixins';

.resume {
	.summary {
		margin: {
			bottom: 10px;
		}
		border: {
			bottom: {
				width: 1px;
				style: solid;
				color: $silver;
			};
			top: {
				width: 1px;
				style: solid;
				color: $silver;
			}
		}
		line-height: 1.4;

		&__text {
			display: block;
			padding: 15px 0 25px 0px;
			font: {
				family: $roboto;
			}
			font-style: italic;
			color: $tuna;
		}
	}


	.preview-section {
		margin-top: 20px;

		.preview {
			display: block;
			margin: 0 auto;
			max-width: 890px;
		}

		.stub {
			.section {
				padding: 24px 0;

				& + .section {
					position: relative;

					&::before {
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						width: 495px;
						height: 1px;
						background: $silver;
					}
				}

				&:first-child {
					padding-top: 0;
				}

				&:last-child {
					padding-bottom: 0;
				}
			}

			.title {
				font-weight: $normal;
				color: $tuna;
				font-size: 20px;
			}
		}
	}

	.warning {
		text-align: center;
		border-top: 1px solid $silver;
		margin: 45px 0 56px;
		padding-top: 24px;

		.text {
			font-size: 16px;
			font-family: $openSans;
			color: $tuna;

			&.highlighted {
				font-weight: $demiBold;
				font-size: 18px;
				margin-top: 8px;
			}
		}

		.link {
			@extend %link;
		}
	}
}
