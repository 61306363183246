@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.job-settings {
	margin-top: 50px;

	.settings-section {
		margin-bottom: 48px;

		.status-info {
			margin-bottom: 16px;

			.text {
				font-size: 14px;

				.highlight {
					font-weight: $demiBold;
					font-style: italic;
				}
			}
		}
	}

	.profile-name {
		font-size: 16px;
		font-weight: $normal;
		color: $tuna;
	}

	.popover-width {
		max-width: 400px !important;
		top: 18px;
	}

	.info-box {
		margin-left: 12px;
		display: inline;
		line-height: 16px;
	}

	.info-content {
		.title {
			font-size: 14px;
			font-weight: $demiBold;
			margin-bottom: 8px;
		}

		.text {
			font-size: 14px;
			line-height: 20px;
		}
	}

	.notifications {
		.notifications-title {
			margin-top: 48px;
			margin-bottom: 32px;
			font-size: 20px;
			font-weight: $demiBold;
			letter-spacing: 0.45px;
			color: rgba($tuna, 0.55);
		}

		.section-header {
			margin-bottom: 8px !important;
		}

		.section-title {
			position: relative;

			.title-loader {
				position: absolute;
				top: 4px;
				margin-left: 8px;
			}

			.btn-link {
				font-weight: $demiBold;
				position: absolute;
				right: 0;
				top: 0;

				@include breakpoint($mobile, $tablet) {
					position: relative;
					display: block;
				}
			}
		}

		.description {
			font-weight: $normal;
			color: $tunaDarkBlack;
		}

		.empty {
			font-style: italic;
			margin-top: 12px;
		}
	}

	.loader {
		display: flex;
		margin: 20px auto;
	}

	.section-header {
		font-size: 20px;
		font-weight: $demiBold;
	}
}

.confirmation-modal {
	width: 387px !important;
}

.modal-title {
	font-size: 28px !important;
	font-weight: $normal !important;
	margin-bottom: 16px !important;
}

.modal-description {
	text-align: center;

	.text {
		.highlighted {
			font-weight: $demiBold;
		}
	}
}
